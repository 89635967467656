import React from "react"
import {
    graphql
} from 'gatsby'


import styled from "styled-components"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import {
    Container
} from "../components/global"
import Footer from "../components/sections/footer"

import { useFirebase } from "gatsby-plugin-firebase"

const DocsPage = (props) => {
    useFirebase(firebase => { firebase.analytics().logEvent("page_view_docs") }, [])
    const docsList = props.data.allMarkdownRemark;
    return (
      <Layout>
        <SEO title="apocha" />
        <Navigation />
        <BlogContainer>
          <h3>apocha Dokumentation</h3>
          {docsList.edges.map(({ node }, i) => (
            <p>{node.frontmatter.title} --&gt; <a href={node.fields.slug}>{node.fields.slug}</a></p>
          ))}
        </BlogContainer>
        <Footer />
      </Layout>
    )
}

export default DocsPage

export const docsQuery = graphql `
  query DocsQuery {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(docs)/"  }},
      sort: { order: ASC, fields: [frontmatter___index] }) {
      edges {
        node {
          fields{
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

const BlogContainer = styled(Container)
`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
